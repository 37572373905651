.page-full {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
}
.page-full.use-bg {
    background-image: url("../public/assets/wallpapers/white.jpg");
    background-size: 100% 100%;
}

.page-block {
    height: calc(100vh - 32px);
}

.global-menu {
    display: flex;
    padding: 0 12px;
    align-items: center;
}
.global-menu .menu-item {
    margin: 0 4px;
    cursor: pointer;
}

.global-menu .menu-item.active {
    color: #33afff;
}
.global-menu .menu-item:hover {
    color: #33afff !important;
}

.setter-level-2 {
    position: relative;
    border: 2px lightgray dashed;
    padding: 10px 6px;
    border-radius: 6px;
    margin-top: 24px;
}
.setter-level-2>.title {
    position: absolute;
    left: 16px;
    top: -12px;
    background: #f2f2f2;
}
.meta-component-editing:not(.meta-component-selected):hover {
    border: 2px dashed #1677ffaa;
}
.meta-component-editing .component-edit-tip>.widgets{
    background-color: #1677ffaa;
    color: #fff;
}
.meta-component-editing.meta-component-selected{
    border: 2px solid #1677ff;
    position: relative;
}

.meta-component-editing>.component-edit-tip {
    display: none;
    position: absolute;
    left: 0; 
    flex-direction: row-reverse;
    bottom: -24px;
    width: 100%;
}
.meta-component-editing.meta-component-selected>.component-edit-tip,
.meta-component-editing:hover>.component-edit-tip {
    display: flex;
}

.meta-component-editing.meta-component-selected>.component-edit-tip>.widgets {
    background-color: #1677ff;
    color: #fff;
}